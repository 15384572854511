import React, {Fragment, useEffect, useMemo, useState} from "react";
import {useNavigate} from "react-router-dom";
import {
    faArrowRightToBracket,
    faBullhorn,
    faCalculator,
    faClipboardCheck,
    faComments,
    faDatabase,
    faFileCircleCheck,
    faServer,
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import classNames from "classnames/bind";
import dayjs from "dayjs";

import {useBoardList} from "~/data/board/use-board-list";
import {useCustomTasksLists} from "~/data/check/task/use-tasks";
import {useWeekWeather} from "~/data/dashboard/weather/use-week-weather";
import {useMergeBoardList} from "~/data/merge/board";
import {useUser} from "~/data/user";
import {InquiryBoardModal} from "~/pages/board/inquiry/modal/inquiry-board.modal";
import {NoticeBoardModal} from "~/pages/board/notice/modal/notice-board.modal";
import {DashboardCalendar} from "~/pages/dashboard/calendar/dashboard-calendar";
import CostIntegratedTable from "~/pages/dashboard/cost-integrated-table";
import {DashboardAlertModal} from "~/pages/dashboard/modal/dashboard-alert.modal";
import {ManualBoardModal} from "~/pages/manual/modal/manual-board.modal";
import {MergeBoardAnswerModal} from "~/pages/merge/answer/merge-board-answer.modal";
import {MergeBoardDetailModal} from "~/pages/merge/detail/merge-board-detail.modal";
import {getWeatherFormText} from "~/utills/get-weather";

import {CheckStatusManager} from "./check-status-manager";
import {CheckStatusMember} from "./check-status-member";

import styles from "./pc-dashboard.page.module.scss";

const cx = classNames.bind(styles);

const PcDashboardPage = () => {
    const [noticeModal, setNoticeModal] = useState<false | number>(false);
    const [inquiryModal, setInquiryModal] = useState<false | number>(false);
    const [mergeModal, setMergeModal] = useState<false | number>(false);
    const [manualModal, setManualModal] = useState<false | number>(false);
    const [alertModal, setAlertModal] = useState<boolean>(true);
    const today = useMemo(() => dayjs(), []);

    const {user} = useUser();
    // const {weather, weatherLoading} = useWeekWeather();
    const navigate = useNavigate();

    const [requestInquiryBoardList, inquiryTableList, isInquiryListLoading] = useBoardList({
        category: "inquiry",
        title: "",
        startDate: today.add(-30, "day").format("YYYY-MM-DD"),
        endDate: today.format("YYYY-MM-DD"),
        page: 1,
        perPage: 5,
    });
    const [requestBoardList, tableList, isListLoading] = useBoardList({
        category: "notice",
        title: "",
        startDate: today.add(-30, "day").format("YYYY-MM-DD"),
        endDate: today.format("YYYY-MM-DD"),
        page: 1,
        perPage: 5,
    });
    const [requestManualBoardList, manualTableList, isManualListLoading] = useBoardList({
        category: "manual",
        title: "",
        startDate: null,
        endDate: null,
        page: 1,
        perPage: 5,
    });

    const [requestMergeBoardList, mergeBoardList, mergeBoardListLoading] = useMergeBoardList({
        isComplete: "N",
        mergeType: "",
        year: null,
        month: null,
        startDate: today.startOf("month").format("YYYY-MM-DD"),
        endDate: today.endOf("month").format("YYYY-MM-DD"),
        title: "",
        pageNo: 1,
        recordPerPage: 5,
        selected: null,
    });

    const {weekWeather, weekWeatherLoading} = useWeekWeather();

    const {tasksLists, tasksListsTotalCount, tasksListsLoading, tasksListsRefresh} = useCustomTasksLists(
        Number(user?.buildingId),
    );

    useEffect(() => {
        requestBoardList();
        requestInquiryBoardList();
        requestMergeBoardList();
        requestManualBoardList();
        tasksListsRefresh();
    }, []);

    return (
        <Fragment>
            {alertModal && user?.division === "MANAGER" && (
                <DashboardAlertModal
                    close={() => setAlertModal(false)}
                    isShow={alertModal}
                    noticeList={tableList?.list ?? []}
                    mergeList={mergeBoardList?.resultList ?? []}
                    mergeLoading={mergeBoardListLoading}
                />
            )}
            {noticeModal && (
                <NoticeBoardModal
                    close={() => setNoticeModal(false)}
                    noticeId={noticeModal}
                    refresh={() => {
                        return false;
                    }}
                />
            )}
            {inquiryModal && (
                <InquiryBoardModal
                    close={() => setInquiryModal(false)}
                    noticeId={inquiryModal}
                    refresh={() => {
                        return false;
                    }}
                />
            )}
            {manualModal && (
                <ManualBoardModal
                    close={() => setManualModal(false)}
                    noticeId={manualModal}
                    refresh={() => {
                        return false;
                    }}
                />
            )}
            {mergeModal &&
                (user?.division === "MEMBER" ? (
                    <MergeBoardDetailModal
                        submit={requestMergeBoardList}
                        noticeMergeId={mergeModal}
                        close={() => setMergeModal(false)}
                    />
                ) : (
                    <MergeBoardAnswerModal
                        submit={requestMergeBoardList}
                        noticeMergeId={mergeModal}
                        close={() => setMergeModal(false)}
                    />
                ))}

            <div>
                <div className={cx("row")}>
                    {user?.division === "MEMBER" && <CheckStatusMember />}
                    {user?.division === "MANAGER" && <CheckStatusManager />}

                    <div className={cx("card", "calendar")}>
                        <h4 className={cx("title")}>캘린더</h4>
                        <div className={cx("body")}>
                            <DashboardCalendar />
                        </div>
                    </div>
                    <div className={cx("card")}>
                        <h4 className={cx("title")}>로그인정보</h4>
                        <div className={cx("body")}>
                            <p className={cx("user-info")}>
                                <span className={cx("user-label")}>부문</span>
                                {user?.sectorName} <span className={cx("user-label")}>지점</span>
                                {user?.buildingName}
                            </p>
                            <p className={cx("user-name")}>
                                <span className={cx("user-label")}>이름(ID)</span>
                                {user?.name}({user?.username})
                            </p>
                        </div>
                    </div>
                    <div className={cx("card")}>
                        {weekWeatherLoading && (
                            <div className={cx("loading", {active: isInquiryListLoading})}>
                                <div className={cx("loading-text")}>Loading</div>
                            </div>
                        )}
                        <h4 className={cx("title")}>
                            <span>주간 날씨</span> <small style={{color: "#aaaaaa"}}>(출처 : 기상청)</small>
                        </h4>
                        <div className={cx("body")}>
                            <div className={cx("weather-wrapper")}>
                                {weekWeather.map((item, index) => {
                                    if (index > 6) return null;
                                    return (
                                        <div className={cx("weather")} key={item.date}>
                                            <img
                                                className={cx("weather-image")}
                                                src={require(
                                                    `../../images/weathers/${getWeatherFormText(item.textAM + "/" + item.textPM).icon}.png`,
                                                )}
                                            />
                                            <p className={cx("temperature")}>
                                                {item.minTemperature}°/{item.maxTemperature}°
                                            </p>
                                            <p
                                                className={cx("temperature", "info")}
                                                style={{
                                                    color:
                                                        item.dayOfWeek === "토요일"
                                                            ? "#4475cc"
                                                            : item.dayOfWeek === "일요일"
                                                              ? " #c81d1d"
                                                              : "",
                                                }}
                                            >
                                                {item.dayOfWeek}
                                            </p>
                                            <p className={cx("temperature", "date")}>{item.date}</p>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {user?.division === "MEMBER" ? (
                <>
                    <div className={cx("row")}>
                        <div className={cx("card")}>
                            <div className={cx("loading", {active: mergeBoardListLoading})}>
                                <div className={cx("loading-text")}>Loading</div>
                            </div>
                            <h4 className={cx("title")}>
                                <span className={cx("clickable")} onClick={() => navigate("/merge")}>
                                    취합게시판
                                </span>
                                <FontAwesomeIcon
                                    icon={faArrowRightToBracket}
                                    className={cx("title-icon")}
                                    onClick={() => navigate("/merge")}
                                />
                            </h4>
                            <div className={cx("body")}>
                                {mergeBoardList?.resultList.length === 0 && (
                                    <h6 className={cx("empty")}>진행중인 취합업무가 없습니다.</h6>
                                )}
                                <ul className={cx("list")}>
                                    {mergeBoardList?.resultList.map((item: any) => (
                                        <li
                                            key={item.title + item.createDate}
                                            className={cx("item")}
                                            onClick={() => setMergeModal(item.noticeMergeId)}
                                        >
                                            <span className={cx("board-title")}>
                                                {user?.division === "MEMBER" && (
                                                    <span
                                                        className={cx("inquiry-status", {
                                                            etc: item.targetCnt !== item.completeCnt,
                                                        })}
                                                    >
                                                        {item.targetCnt} / {item.completeCnt}
                                                    </span>
                                                )}
                                                {item.title}
                                            </span>
                                            <span className={cx("board-date")}>{item.createDate.substring(0, 10)}</span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className={cx("card", "cost-integrated")}>
                            <div className={cx("loading")}>
                                <div className={cx("loading-text")}>Loading</div>
                            </div>
                            <h4 className={cx("title")}>
                                <span className={cx("clickable")} onClick={() => navigate("/cost/integrated")}>
                                    예산
                                </span>
                                <FontAwesomeIcon
                                    icon={faArrowRightToBracket}
                                    className={cx("title-icon")}
                                    onClick={() => navigate("/cost/integrated")}
                                />
                            </h4>
                            <div className={cx("body")} style={{paddingTop: 10}}>
                                <CostIntegratedTable />
                            </div>
                        </div>
                    </div>
                    <div className={cx("row")}>
                        <div className={cx("card")}>
                            <div className={cx("loading", {active: isManualListLoading})}>
                                <div className={cx("loading-text")}>Loading</div>
                            </div>
                            <h4 className={cx("title")}>
                                <span>게시판</span>
                            </h4>
                            <div className={cx("body")}>
                                <ul className={cx("quick-list")}>
                                    <li
                                        className={cx("quick-item", "clickable")}
                                        onClick={() => navigate("/board/notice")}
                                    >
                                        <div className={cx("quick-menu-icon")}>
                                            <FontAwesomeIcon icon={faBullhorn} className={cx("icon")} />
                                        </div>
                                        <div className={cx("quick-menu-text")}>
                                            <span>공지사항</span>
                                        </div>
                                    </li>
                                    <li
                                        className={cx("quick-item", "clickable")}
                                        onClick={() => navigate("/board/inquiry")}
                                    >
                                        <div className={cx("quick-menu-icon")}>
                                            <FontAwesomeIcon icon={faComments} className={cx("icon")} />
                                        </div>
                                        <div className={cx("quick-menu-text")}>
                                            <span>문의게시판</span>
                                        </div>
                                    </li>
                                    <li
                                        className={cx("quick-item", "clickable")}
                                        onClick={() => navigate("/board/manual")}
                                    >
                                        <div className={cx("quick-menu-icon")}>
                                            <FontAwesomeIcon icon={faFileCircleCheck} className={cx("icon")} />
                                        </div>
                                        <div className={cx("quick-menu-text")}>
                                            <span>매뉴얼</span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className={cx("card")}>
                            <h4 className={cx("title")}>
                                <span>바로가기</span>
                            </h4>
                            <div className={cx("body")}>
                                <ul className={cx("quick-list")}>
                                    <li className={cx("quick-item", "clickable")} onClick={() => navigate("/drive")}>
                                        <div className={cx("quick-menu-icon")}>
                                            <FontAwesomeIcon icon={faDatabase} className={cx("icon")} />
                                        </div>
                                        <div className={cx("quick-menu-text")}>
                                            <span>자료실</span>
                                        </div>
                                    </li>
                                    <li className={cx("quick-item", "clickable")} onClick={() => navigate("/merge")}>
                                        <div className={cx("quick-menu-icon")}>
                                            <FontAwesomeIcon icon={faClipboardCheck} className={cx("icon")} />
                                        </div>
                                        <div className={cx("quick-menu-text")}>
                                            <span>취합</span>
                                        </div>
                                    </li>
                                    <li
                                        className={cx("quick-item", "clickable")}
                                        onClick={() => navigate("/cost/integrated")}
                                    >
                                        <div className={cx("quick-menu-icon")}>
                                            <FontAwesomeIcon icon={faCalculator} className={cx("icon")} />
                                        </div>
                                        <div className={cx("quick-menu-text")}>
                                            <span>예산</span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className={cx("card")}>
                            <h4 className={cx("title")}>
                                <span>고객센터</span>
                            </h4>
                            <div className={cx("body")}>
                                <ul className={cx("list")}>
                                    <li className={cx("item")}>070-4411-3170</li>
                                    <li className={cx("item")}>(운영시간 AM 10:00 ~ PM 5:00)</li>
                                    <li className={cx("item")}>email : cs@icloudn.net</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div className={cx("row")}>
                        <div className={cx("card")}>
                            <div className={cx("loading", {active: isListLoading})}>
                                <div className={cx("loading-text")}>Loading</div>
                            </div>
                            <h4 className={cx("title")}>
                                <span className={cx("clickable")} onClick={() => navigate("/board/notice")}>
                                    공지사항
                                </span>
                                <FontAwesomeIcon
                                    icon={faArrowRightToBracket}
                                    className={cx("title-icon")}
                                    onClick={() => navigate("/board/notice")}
                                />
                            </h4>
                            <div className={cx("body")}>
                                {tableList?.list.length === 0 && <h6 className={cx("empty")}>내용이 없습니다.</h6>}
                                <ul className={cx("list")}>
                                    {tableList?.list.map((item) => {
                                        return (
                                            <li
                                                key={item.id}
                                                className={cx("item")}
                                                onClick={() => setNoticeModal(item.id)}
                                            >
                                                <span className={cx("board-title")}>{item.title}</span>
                                                <span className={cx("board-date")}>
                                                    {item.createDate.substring(0, 10)}
                                                </span>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        </div>

                        <div className={cx("card", "merge")}>
                            <div className={cx("loading", {active: mergeBoardListLoading})}>
                                <div className={cx("loading-text")}>Loading</div>
                            </div>
                            <h4 className={cx("title")}>
                                <span className={cx("clickable")} onClick={() => navigate("/merge")}>
                                    취합게시판
                                </span>
                                <FontAwesomeIcon
                                    icon={faArrowRightToBracket}
                                    className={cx("title-icon")}
                                    onClick={() => navigate("/merge")}
                                />
                            </h4>
                            <div className={cx("body")}>
                                {mergeBoardList?.resultList.length === 0 && (
                                    <h6 className={cx("empty")}>진행중인 취합업무가 없습니다.</h6>
                                )}
                                <ul className={cx("list")}>
                                    {mergeBoardList?.resultList.map((item: any) => (
                                        <li
                                            key={item.title + item.createDate}
                                            className={cx("item")}
                                            onClick={() => setMergeModal(item.noticeMergeId)}
                                        >
                                            <span className={cx("board-title")}>
                                                {user?.division === "MEMBER" && (
                                                    <span
                                                        className={cx("inquiry-status", {
                                                            etc: item.targetCnt !== item.completeCnt,
                                                        })}
                                                    >
                                                        {item.targetCnt} / {item.completeCnt}
                                                    </span>
                                                )}
                                                {item.title}
                                            </span>
                                            <span className={cx("board-date")}>{item.createDate.substring(0, 10)}</span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className={cx("row")}>
                        <div className={cx("card")}>
                            <div className={cx("loading", {active: tasksListsLoading})}>
                                <div className={cx("loading-text")}>Loading</div>
                            </div>
                            <h4 className={cx("title")}>
                                <span>이달의 점검항목</span>
                            </h4>
                            <div className={cx("body")}>
                                {tasksListsTotalCount === 0 && <h6 className={cx("empty")}>점검항목이 없습니다.</h6>}
                                <ul className={cx("list")}>
                                    {tasksLists?.map((item) => {
                                        return (
                                            <li
                                                key={item.checkFormId}
                                                className={cx("item")}
                                                onClick={() =>
                                                    navigate(
                                                        `/check/${item.category === "ADD" ? "extra" : "normal"}/history`,
                                                    )
                                                }
                                            >
                                                <span className={cx("board-title")}>
                                                    <span className={cx("inquiry-status", "etc")}>
                                                        {item.category === "ADD" ? "일반" : "추가"}
                                                    </span>
                                                    {item.checkFormName}
                                                </span>
                                                <span className={cx("board-date")}>
                                                    {item.pattern?.trim().split(" ").join(", ")} 일
                                                </span>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        </div>
                        <div className={cx("card")}>
                            <div className={cx("loading", {active: isInquiryListLoading})}>
                                <div className={cx("loading-text")}>Loading</div>
                            </div>
                            <h4 className={cx("title")}>
                                <span className={cx("clickable")} onClick={() => navigate("/board/inquiry")}>
                                    문의게시판
                                </span>
                                <FontAwesomeIcon
                                    icon={faArrowRightToBracket}
                                    className={cx("title-icon")}
                                    onClick={() => navigate("/board/inquiry")}
                                />
                            </h4>
                            <div className={cx("body")}>
                                {inquiryTableList?.list.length === 0 && (
                                    <h6 className={cx("empty")}>문의내용이 없습니다.</h6>
                                )}
                                <ul className={cx("list")}>
                                    {inquiryTableList?.list.map((item) => {
                                        return (
                                            <li
                                                key={item.id}
                                                className={cx("item")}
                                                onClick={() => setInquiryModal(item.id)}
                                            >
                                                <span className={cx("board-title")}>
                                                    <span
                                                        className={cx("inquiry-status", {
                                                            etc: item.status !== "접수완료",
                                                        })}
                                                    >
                                                        {item.status}
                                                    </span>
                                                    {item.title}
                                                </span>
                                                <span className={cx("board-date")}>
                                                    {item.createDate.substring(0, 10)}
                                                </span>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        </div>
                        <div className={cx("card")}>
                            <div className={cx("loading", {active: isManualListLoading})}>
                                <div className={cx("loading-text")}>Loading</div>
                            </div>
                            <h4 className={cx("title")}>
                                <span className={cx("clickable")} onClick={() => navigate("/board/manual")}>
                                    매뉴얼 게시판
                                </span>
                                <FontAwesomeIcon
                                    icon={faArrowRightToBracket}
                                    className={cx("title-icon")}
                                    onClick={() => navigate("/board/manual")}
                                />
                            </h4>
                            <div className={cx("body")}>
                                {manualTableList?.list.length === 0 && (
                                    <h6 className={cx("empty")}>내용이 없습니다.</h6>
                                )}
                                <ul className={cx("list")}>
                                    {manualTableList?.list.map((item) => {
                                        return (
                                            <li
                                                key={item.id}
                                                className={cx("item")}
                                                onClick={() => setManualModal(item.id)}
                                            >
                                                <span className={cx("board-title")}>
                                                    {dayjs(item.createDate).isAfter(dayjs().subtract(7, "day")) && (
                                                        <span className={cx("inquiry-status")}>N</span>
                                                    )}
                                                    {item.title}
                                                </span>
                                                <span className={cx("board-date")}>
                                                    {item.createDate.substring(0, 10)}
                                                </span>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className={cx("row")}>
                        <div className={cx("card")}>
                            <h4 className={cx("title")}>
                                <span>바로가기</span>
                            </h4>
                            <div className={cx("body")}>
                                <ul className={cx("quick-list")}>
                                    <li className={cx("quick-item", "clickable")} onClick={() => navigate("/drive")}>
                                        <div className={cx("quick-menu-icon")}>
                                            <FontAwesomeIcon icon={faDatabase} className={cx("icon")} />
                                        </div>
                                        <div className={cx("quick-menu-text")}>
                                            <span>자료실</span>
                                        </div>
                                    </li>
                                    <li className={cx("quick-item", "clickable")} onClick={() => navigate("/merge")}>
                                        <div className={cx("quick-menu-icon")}>
                                            <FontAwesomeIcon icon={faClipboardCheck} className={cx("icon")} />
                                        </div>
                                        <div className={cx("quick-menu-text")}>
                                            <span>취합</span>
                                        </div>
                                    </li>
                                    <li
                                        className={cx("quick-item", "clickable")}
                                        onClick={() => navigate("/equipment")}
                                    >
                                        <div className={cx("quick-menu-icon")}>
                                            <FontAwesomeIcon icon={faServer} className={cx("icon")} />
                                        </div>
                                        <div className={cx("quick-menu-text")}>
                                            <span>설비현황</span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className={cx("card")}>
                            <h4 className={cx("title")}>
                                <span>고객센터</span>
                            </h4>
                            <div className={cx("body")}>
                                <ul className={cx("list")}>
                                    <li className={cx("item")}>070-4411-3170</li>
                                    <li className={cx("item")}>(운영시간 AM 10:00 ~ PM 5:00)</li>
                                    <li className={cx("item")}>email : cs@icloudn.net</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </Fragment>
    );
};

export {PcDashboardPage};
